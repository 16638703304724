<template>
    <div class="container" v-cloak>
        <div class="px-3">
            <div class="row">
                <div class="col-12 px-3">
                    <button class="btn btn-common btn-back" @click="back">
                        Home
                    </button>
                </div>
            </div>
            <div class="row justify-content-md-center py-3">
                <div class="col-md-6">
                    <h3 class="h3">Login</h3>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-md-6">
                    <b-tabs content-class="mt-3">
                        <b-tab title="With password" active>
                            <form>
                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        v-model="email"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="password"
                                        name="password"
                                        v-model="password"
                                        required
                                    />
                                </div>
                                <button
                                    class="btn btn-common btn-green my-2"
                                    v-bind:disabled="!(email && password)"
                                    @click.prevent="loginWithPassword"
                                >
                                    Login
                                </button>
                            </form>
                        </b-tab>

                        <b-tab title="With verification code">
                            <form>
                                <div class="form-group">
                                    <label for="email"
                                        >Email
                                        <span class="email-subtitle"
                                            >The PayPal email you've ever paid
                                            with</span
                                        >
                                    </label>

                                    <input
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        v-model="email"
                                        required
                                    />
                                </div>
                                <button
                                    v-show="!verificationCodeEmailSent"
                                    class="btn btn-common btn-green my-2"
                                    @click.stop.prevent="
                                        sendLoginVerificationCode
                                    "
                                >
                                    Send verification code
                                </button>

                                <div v-if="verificationCodeEmailSent">
                                    <div class="email-sent-tip">
                                        Verification code has been sent to
                                        <span>{{ email }}</span>
                                    </div>

                                    <input
                                        class="form-control verification-code"
                                        type="text"
                                        inputmode="numeric"
                                        pattern="[0-9]*"
                                        v-model="verificationCode"
                                        @input="limitToSixDigits"
                                        maxlength="6"
                                        ref="verificationCodeInput"
                                    />
                                </div>
                            </form>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { authService } from "../service/index";
import jwt from "jsonwebtoken";

export default {
    name: "Login",
    data() {
        return {
            email: null,
            password: null,

            showPasswordLogin: true,
            showVerificationCodeLogin: false,

            showSendVerificationCodeEmailButton: false,
            verificationCodeEmailSent: false,
            verificationCode: null
        };
    },
    mounted() {
        this.email = this.$route.params.email;
    },
    watch: {
        verificationCode(newValue, oldValue) {
            if (newValue.length == 6) {
                this.loginWithVerificationCode();
            }
        }
    },
    methods: {
        back() {
            this.$router.push({ name: "home" });
        },
        limitToSixDigits() {
            // Remove any non-digit characters
            this.verificationCode = this.verificationCode.replace(/\D/g, "");

            // Limit to 6 digits
            if (this.verificationCode.length > 6) {
                this.verificationCode = this.verificationCode.slice(0, 6);
            }
        },
        loginWithPassword() {
            let self = this;

            self.$store.commit("setShowLoading", true);

            let email = self.email.trim();
            let password = self.password.trim();

            const url = `/api/v1/auth/login_with_password`;

            self.$http
                .post(url, {
                    email,
                    password
                })
                .then(function(res) {
                    if (res.data) {
                        let decoded_jwt = jwt.decode(res.data.access_token);

                        if (decoded_jwt) {
                            self.loggedIn = true;
                            self.showQueryButton = true;
                            self.loggedInEmail = email;

                            authService.login(
                                res.data.access_token,
                                res.data.refresh_token,
                                decoded_jwt.user
                            );
                        }
                    } else {
                        alert("Invalid access token!");
                        return;
                    }

                    self.$router.push("/");

                    self.$notify({
                        group: "main",
                        type: "success",
                        text: "Logged in!"
                    });
                })
                .catch(function(error) {
                    // Has been handled in interceptor
                })
                .finally(function() {
                    self.$store.commit("setShowLoading", false);
                });
        },
        sendLoginVerificationCode() {
            let self = this;

            self.$store.commit("setShowLoading", true);

            let email = self.email.trim();

            const url = `/api/v1/auth/send_login_verification_code_email`;

            self.$http
                .post(url, {
                    email
                })
                .then(function(response) {
                    self.verificationCodeEmailSent = true;
                    self.$nextTick(() => {
                        self.$refs.verificationCodeInput.focus();
                    });
                })
                .catch(function(error) {
                    // Handled by interceptor
                })
                .finally(function() {
                    self.$store.commit("setShowLoading", false);
                });
        },
        loginWithVerificationCode() {
            let self = this;

            self.$store.commit("setShowLoading", true);

            let email = self.email.trim();

            const url = `/api/v1/auth/login_with_verification_code`;

            self.$http
                .post(url, {
                    email,
                    code: self.verificationCode
                })
                .then(function(res) {
                    if (res.data) {
                        let decoded_jwt = jwt.decode(res.data.access_token);

                        if (decoded_jwt) {
                            self.loggedIn = true;
                            self.showQueryButton = true;
                            self.loggedInEmail = email;

                            authService.login(
                                res.data.access_token,
                                res.data.refresh_token,
                                decoded_jwt.user
                            );
                        }
                    } else {
                        alert("Invalid access token!");
                        return;
                    }

                    self.$router.push("/");

                    self.$notify({
                        group: "main",
                        type: "success",
                        text: "Logged in!"
                    });
                })
                .catch(function(error) {
                    // Handled by interceptor
                })
                .finally(function() {
                    self.$store.commit("setShowLoading", false);
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.email-subtitle {
    display: block;
    font-size: 0.875em;
    font-weight: normal;
    color: #6c757d;
    margin-top: 0.25rem;
}

.email-sent-tip {
    color: green;
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid rgb(214, 248, 237);
    background-color: rgb(241, 248, 246);
}

.verification-code {
    height: 70px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
}
</style>
